import React, { useEffect, useState } from "react"
import QueryString from "query-string"
import PageRoot from "../../components/PageRoot"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import ErrorScreen from "../../components/ErrorScreen"
import { getErrorMessageByCode } from "../../utils"
import { useTranslation } from "react-i18next"

const CheckoutFailPage = ({ location }) => {
  const params = QueryString.parse(location.search)
  const { message, code } = params
  const { t } = useTranslation()

  const [decodedMessage, setDecodedMessage] = useState()

  useEffect(() => {
    if (message) {
      setDecodedMessage(window.atob(message))
    } else if (code) {
      setDecodedMessage(getErrorMessageByCode(code))
    }
  }, [])

  return (
    <PageRoot title={t("label:paymentFailed")} isDark={false}>
      <ContainerWithPadding size="small" align="center">
        <ErrorScreen error={new Error(code ?? decodedMessage)} />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default CheckoutFailPage
